import React,{useState,useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userState } from '../State.js'

import UserEmail from './UserEmail.js'
// import {useMatchMedia} from '../helpers/MatchMedia.js';
import {useMatchMedia} from 'react-hook-match-media';
// import city from '../assets/images/city.png'
import logo from '../assets/images/prop_report_logo.png'
import search_reports from '../assets/images/search-all-reports.png'
import myAccount from '../assets/icons/_i_icon_04091_icon_040910_256.png'
import writeReport from '../assets/icons/_i_icon_02451_icon_024510_256.png'



const Navbar = (props)=>{
	const isSmallScreen = useMatchMedia('(max-width: 700px)');
	const [currentUser] = useRecoilState(userState) 

	let styles = {
	activeLink: {
		color:'black',
		textDecoration:'underline'
	},
	defaultLink: {
		color:'black'
	},
	main:{
		// paddingBottom: '.5em',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: '#eeeeee',
		color: 'black',
		boxSizing: 'border-box',
		position: 'fixed',
		top: '0',
		width: '100%',
		zIndex: '1',
		opacity: '.9',
		maxWidth: '100%',
		flexDirection: isSmallScreen ? 'column':'row'
	}
}



	return <nav className='uk-container navbar' style={styles.main}>

	<NavLink  
	to={'/'}
	style={ ({isActive}) => (
		isActive ? styles.activeLink : styles.defaultLink
		)}
	>
	<img src={logo} id='logo_icon' alt='building icon'/>
	</NavLink>
	<div className='flexContainerRow' >
	<NavLink  
	title='See all reports'
	to={'/properties'}
	style={ ({isActive}) => (
		isActive ? styles.activeLink : styles.defaultLink
		)}
	>
	{isSmallScreen ? <img src={search_reports} alt="Search properties reports" title="Search reports" style={{"height":"20px"}}/> : 'Search reports'}
	</NavLink>


	<NavLink  
	titl='Write a new report'
	to={'/writereport'}
	style={ ({isActive}) => (
		isActive ? styles.activeLink : styles.defaultLink
		)}
	>
	{isSmallScreen ? <img src={writeReport} style={{"height":"20px"}} title='Write a report'/>  : 'Write a report'}
	</NavLink>


{/* only for logged users */}
	{currentUser.isLoggedIn &&
	<>	
	<NavLink  
	to={'/myaccount'}
	style={ ({isActive}) => (
		isActive ? styles.activeLink : styles.defaultLink
		)}
	>
	{isSmallScreen ? <img title='My account' src={myAccount} style={{"height":"20px"}}/>  : 'My account' }
	</NavLink>
	</>
}
<UserEmail functions={props.functions}/>
</div>
</nav>
}



export default Navbar