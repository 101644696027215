import React, {useEffect} from 'react'
import {useParams,useNavigate} from 'react-router-dom'
import Spinner from './Spinner'


export default function Enter({signIn}) {
	let params = useParams();
	let navigate=useNavigate()
	const {email,link}=params
	
	useEffect(()=>{
		signIn(email,link)
		navigate('/')
	},[email,link])

	return (
		<div>
		<p>Verifying your magic link</p>
		<Spinner/>
		</div>
		)
}
