import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useRecoilValue } from 'recoil'
import { propState, userState } from '../State.js'
import axios from 'axios'
import {backEndURL} from '../config.js'
import Stars from '../components/Stars.js'

import early_adopter from '../assets/badges/early_adopter.png'
import report_machine from '../assets/badges/report_machine.png'

const badges = {early_adopter,report_machine}

const MyAccount = (props) => {

	const [currentUser, setCurrentUser] = useRecoilState(userState)
	const [editing, setEditing]=useState(false)
	const [newUserName, setNewUserName]=useState('')


	let navigate = useNavigate()
	const propData = useRecoilValue(propState)

	const updateUserInDB = async (u) => {
		try{
			const res = await axios.post(`${backEndURL}/users/update`, { _id:currentUser.id,Name: u.name,OpenForContact:u.OpenForContact })
			res.data.ok?alert('Your details were updated'):alert("Hmmm, couldn't update your details")
		} catch (error) {
			console.log(error);
		}
	}


	// console.log(currentUser, propData.filter(p => p.User._id === currentUser.id))

	let renderBadges =()=>(
		currentUser.badges&&currentUser.badges.length>0&&currentUser.badges.map(
			(b,i)=><li key={i}><img className='badge' src={badges[b]} alt={b} /></li>)
	)

	let renderAll = (data) => {
		return data.map((p, idx) => (
			<div key={idx} className='oneProp uk-card uk-card-default uk-card-body uk-width-1@m'>
			<p className="uk-card-title">{p.Street} {p.StreetNumber}, {p.PropFloor&&`${p.PropFloor}`} {p.PropDoor&&` – ${p.PropDoor},`} {p.PostalCode}</p>
			<p>{p.City}, {p.Country}</p>

			{/*<img src={stars[p.PropsRating]} alt='star' />*/}
			{/*{[...Array(p.PropsRating).keys()].map((e,i)=><img style={{'width': '1em'}} key={i} src={star} alt='star' />)}*/}
			<Stars stars={p.PropsRating}/>
			<p>{p.PropReview}</p>


			<button className='uk-button uk-button-default' onClick={() => navigate(`/properties/${p._id}`)}>Read full report</button>

			</div>
			))
	}

	return (
		<div className='allPropsContainer'>
		<div className='uk-card uk-card-default uk-card-body uk-width-1@m'>
		<h2>Hello {currentUser.name}</h2>
		<ul className='listNoBullets'>{renderBadges()}</ul>
		</div>
		<div className='uk-card uk-card-default uk-card-body uk-width-1@m'>
		<p>This is your dashboard. You can see all the reports you posted and view/change your data.</p>
		<div className='flexContainerRow' style={{justifyContent:'start'}}>
		<p>Your name: 
		<span 
		contentEditable={editing? true:false} 
		style={{backgroundColor: editing ? '#c5ffc5':'white', display:'inline-block',padding:'.1em .4em', minWidth:'2em',marginLeft:'.5em'}}
		onInput={e => setNewUserName(e.currentTarget.textContent)}
		>{editing ? '' : currentUser.name}</span>
		</p>
		{
			!editing && 
			<button
			className='uk-button uk-button-default'
			onClick={() => setEditing(!editing)}
			>Click to change</button>
		}
		{
			editing &&
			<button
			className='uk-button uk-button-default'
			onClick={() => {
				setEditing(!editing)
				setCurrentUser({ ...currentUser, name: newUserName })
				updateUserInDB({ ...currentUser, name: newUserName })
			} }
			>Confirm</button>
		}
		</div>
		<p>Your email: {currentUser.email}</p>
		<div className='flexContainerRow' style={{justifyContent:'start'}}>
		<p>Open to be contacted by other users: {currentUser.OpenForContact ? 'yes' : 'no'}</p>
		<button
		className='uk-button uk-button-default'
		onClick={() => { 
			setCurrentUser({ ...currentUser, OpenForContact: !currentUser.OpenForContact }) 
			updateUserInDB({ ...currentUser, OpenForContact: !currentUser.OpenForContact }) }
		}
		>Click to {currentUser.OpenForContact ? 'disable' : 'enable'}</button>
		</div>
		</div>
		<article className='uk-card uk-card-default uk-card-body uk-width-1@m'>
		<h3 >Your reviews ↓</h3>
		<p>New reviews appear after being moderated by the admins.</p>
		</article>
		{renderAll(propData.filter(p => p.User._id === currentUser.id))}
		</div>
		)
}

export default MyAccount