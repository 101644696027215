import React, {useState} from 'react'

function FeedbackRating(props) {
	const {star,subj,starRating,handleForm}=props.data
	const [clicked,setClicked]=useState(null)
	return (
		<>
		<textarea className="uk-textarea" required={subj==='property'?true:false} placeholder={`Please give some feedback about the ${subj}`} onChange={handleForm} name={`${subj}Review`}></textarea>
		<div className='flexContainerRow'>
		<p>Rate the {subj}:</p>
		<div className='ratingStars' onClick={(e,s)=>starRating(e,subj)}>
		<img alt='star' src={star} name='star1' style={{'backgroundColor':clicked>=1 && '#ffbde0','marginRight':'0.5em'}} onClick={()=>setClicked(1)}/>
		<img alt='star' src={star} name='star2' style={{'backgroundColor':clicked>=2 && '#ffbde0','marginRight':'0.5em'}} onClick={()=>setClicked(2)}/>
		<img alt='star' src={star} name='star3' style={{'backgroundColor':clicked>=3 && '#ffbde0','marginRight':'0.5em'}} onClick={()=>setClicked(3)}/>
		<img alt='star' src={star} name='star4' style={{'backgroundColor':clicked>=4 && '#ffbde0','marginRight':'0.5em'}} onClick={()=>setClicked(4)}/>
		<img alt='star' src={star} name='star5' style={{'backgroundColor':clicked>=5 && '#ffbde0','marginRight':'0.5em'}} onClick={()=>setClicked(5)}/>
		</div>
		</div>	
		</>
		)
}

export default FeedbackRating