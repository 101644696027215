import React, {useState} from 'react'
import { useRecoilValue } from 'recoil'
import { propState } from '../State.js'
import {useNavigate} from 'react-router-dom'


const Home = () => {
	let navigate = useNavigate()
	const propData = useRecoilValue(propState)
	let toSplice = [...propData]
	const [propsToRender, setPropsToRender]=useState([...toSplice.splice(-3)])
	let renderLatest =(data)=> {
		return data.map((p,idx)=>(
			<div key={idx} className='topSpacer oneProp uk-card uk-card-default uk-card-body uk-width-1@m uk-card-hover'>
			<p className="uk-card-title">{p.Street} {p.StreetNumber}, {p.PropFloor} – {p.PropDoor}, {p.PostalCode}</p>
			<p>{p.City}, {p.Country}</p>

		{/*<img src={stars[p.PropsRating-1]} />*/}
			<p>{'☆'.repeat(p.PropsRating)}</p>
			<p className='propReview'>{p.PropReview}</p>

			<button className='uk-button uk-button-default' onClick={()=>navigate(`/properties/${p._id}`)}>Read full report</button>

			</div>
			))
	}

	return (
		<div className='flexContainerRowWithWrap' style={{alignItems:'start'}}>
		<div className="uk-card uk-card-default uk-card-body uk-card-media-right uk-width-1@m">

		<h1>About GoodBadPlace.com</h1>
		<section>
		<h2 className='readableH2'>Our Goal</h2>
		<p class="text-gray-500 mt-2">At GoodBadPlace, our goal is to provide a transparent and trustworthy platform for renters and buyers to search for apartments, houses, and neighborhoods. We strive to ensure that all reports on our platform are authentic, objective, and valuable, while also remaining fair to landlords and sellers. To achieve this, we follow several principles:</p>
		<ul class="list-disc list-outside text-gray-500 mt-2 ml-6">
		<li>We do not edit or censor reports submitted to our platform.</li>
		<li>We do not hide or delete reports based on their positive or negative content.</li>
		<li>We require users to provide an email address before posting a report.</li>
		<li>We reserve the right to moderate reports and remove any that we deem inappropriate.</li>
		<li>We remain neutral in the event of any disputes and do not take sides.</li>
		<li>All reports are anonymous, and only we have the means to contact the user who posted a report.</li>
		</ul>
		</section>
		<section>
		<h2 className='readableH2'>We have established guidelines for posting reports on GoodBadPlace.com:</h2>
		<ul class="list-disc list-outside text-gray-500 mt-2 ml-6">
		<li>Reports should be truthful descriptions of a user's personal experience as a renter or owner. We do not fact-check reports, and users are responsible for the accuracy of their submissions.</li>
		<li>Users are encouraged to provide both positive and negative experiences in their reports. We believe that it is valuable for readers to see both sides of a story.</li>
		<li>Users may mention the names of individuals connected to the property they are describing if they have a direct or indirect impact on the living experience. However, users should refrain from personal judgments and focus solely on the individuals' actions related to the living experience.</li>
		<li>Users should not make negative comments about individuals who are not connected to the property they are describing.</li>
		<li>Reports should not contain profanity, threats, or discriminatory language.</li>
		<li>Reports should not contain any private information about any person mentioned in the report.</li>
		<li>Reports should not contain a user's contact information or any other identifying information.</li>
		<li>Users should not include private conversations or correspondence in their reports without permission.</li>
		<li>Users should aim to use proper grammar, spelling, and punctuation to make their reports easier to read for other users.</li>
		</ul>
		</section>

		</div>
		</div>
		)
}

export default Home