import React, {useState} from 'react'
import { useRecoilValue } from 'recoil'
import { propState } from '../State.js'
import {useNavigate} from 'react-router-dom'
import Stars from '../components/Stars'


const Home = () => {
	let navigate = useNavigate()
	const propData = useRecoilValue(propState)
	let toSplice = [...propData]
	const [propsToRender, setPropsToRender]=useState([...toSplice.splice(-3).reverse()])
	let renderLatest =(data)=> {
		return data.map((p,idx)=>(
			<div key={idx} className='topSpacer oneProp uk-card uk-card-default uk-card-body uk-width-1@m uk-card-hover'>
			<p className="uk-card-title">{p.Street} {p.StreetNumber}, {p.PropFloor&&`${p.PropFloor}`} {p.PropDoor&&` – ${p.PropDoor},`} {p.PostalCode}</p>
			<p>{p.City}, {p.Country}</p>

			<Stars stars={p.PropsRating} />
			<p className='propReview'>{p.PropReview}</p>

			<button className='uk-button uk-button-default' onClick={()=>navigate(`/properties/${p._id}`)}>Read full report</button>

			</div>
			))
	}

	return (
		<div className='flexContainerRowWithWrap' style={{alignItems:'start'}}>

		<div className="seeThrough uk-card uk-card-default uk-card-body uk-card-media-right uk-width-1-3@m">
		<h3 className="uk-card-title">Sharing opinions on the places we've lived at</h3>
		<div className='uk-card uk-card-default uk-card-body uk-width-1@m uk-card-hover'>
		<p>Welcome to GoodBadPlace community where we help each other by leaving honest reviews about the places we've lived in.</p> 
		<p>Share your experience, good and bad, to help others make a right decision before renting or buying a property. </p>
		</div>
		</div>

	{/*rendering latest props*/}
		<div className="seeThrough uk-card uk-card-default uk-card-body uk-card-media-right uk-width-1-2@m">
		<h3 className="uk-card-title">Latest Prop Reports</h3>
		{propsToRender.length>0 && renderLatest(propsToRender)}
		</div>

		</div>
		)
}

export default Home