import React from 'react';
import './index.css';
import App from './App';
import * as ReactDOMClient from 'react-dom/client';

import { RecoilRoot } from "recoil";
import "uikit/dist/css/uikit.min.css"
import "uikit/dist/js/uikit.min.js"
import "uikit/dist/js/uikit-icons.min.js"



const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);
root.render(<RecoilRoot><App /></RecoilRoot>);

// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//   <RecoilRoot>
//   <App />
//   </RecoilRoot>
//   </React.StrictMode>,
//   document.getElementById('root')
//   );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();



