import React, {useState} from 'react'
import { useRecoilValue } from 'recoil'
import { propState } from '../State.js'
import {useNavigate} from 'react-router-dom'


const Home = () => {
	let navigate = useNavigate()
	const propData = useRecoilValue(propState)
	let toSplice = [...propData]
	const [propsToRender, setPropsToRender]=useState([...toSplice.splice(-3)])
	let renderLatest =(data)=> {
		return data.map((p,idx)=>(
			<div key={idx} className='topSpacer oneProp uk-card uk-card-default uk-card-body uk-width-1@m uk-card-hover'>
			<p className="uk-card-title">{p.Street} {p.StreetNumber}, {p.PropFloor} – {p.PropDoor}, {p.PostalCode}</p>
			<p>{p.City}, {p.Country}</p>

		{/*<img src={stars[p.PropsRating-1]} />*/}
			<p>{'☆'.repeat(p.PropsRating)}</p>
			<p className='propReview'>{p.PropReview}</p>

			<button className='uk-button uk-button-default' onClick={()=>navigate(`/properties/${p._id}`)}>Read full report</button>

			</div>
			))
	}

	return (
		<div className='flexContainerRowWithWrap' style={{alignItems:'start'}}>
		<div className="uk-card uk-card-default uk-card-body uk-card-media-right uk-width-1@m">
		<h1>Frequently Asked Questions</h1>
		<h2 className='readableH2'>What is GoodBadPlace.com?</h2>
		<p>GoodBadPlace.com is a platform that allows users to share their experiences living in a rental or owned property with others to help them make informed decisions before renting or buying. Users can search for reports by address, street name, or postal code to get a detailed or aggregated understanding of a specific apartment, building, or area.</p>

		<h2 className='readableH2'>Are the reports anonymous?</h2>
		<p>Yes, all reports submitted through GoodBadPlace.com are completely anonymous. GoodBadPlace.com will not share any personal information. If you have any inquiries, please contact us at team1@GoodBadPlace.com.</p>

		<h2 className='readableH2'>Will my landlord see my report?</h2>
		<p>It is possible that your landlord may read and/or respond to your report. However, the report itself does not contain any information that could be used to identify the user. Reports are anonymous, and your landlord will not know who wrote it. Please ensure that your report does not contain any details that could identify you.</p>

		<h2 className='readableH2'>I can’t find my address. What does that mean?</h2>
		<p>You should be able to search for any address available on Google Maps. Unless it is a new building, there should not be any issues. If you are unable to find your address, you can try searching for the nearest address and mention the correct address in your report.</p>

		<h2 className='readableH2'>There is something on GoodBadPlace.com that is not working properly. What should I do?</h2>
		<p>If you encounter any issues with the GoodBadPlace.com platform, please contact us at team1@GoodBadPlace.com and we will try to resolve the issue as soon as possible.</p>

		<h2 className='readableH2'>Which cities/countries are supported on GoodBadPlace.com?</h2>
		<p>GoodBadPlace.com should work for any address in any city/country. However, as GoodBadPlace.com was initially launched in Barcelona, the address format was designed with local address formats in mind. If you have any suggestions for missing fields in the report form, please let us know at team1@GoodBadPlace.com and we will try to modify it as soon as possible.</p>

		<h2 className='readableH2'>I am a property owner and I do not like a negative report posted on GoodBadPlace.com. What should I do?</h2>
		<p>If you believe that a report violates our Community Guidelines, please contact us at team1@GoodBadPlace.com. We will review the report as soon as possible. Please keep in mind the following:</p>
		<p>Every user who submits a report confirms that the experience described is truthful and accurate.</p>
		<p>We do not take sides in any dispute between the reporter and the property owner.</p>
		<p>We cannot disclose the identity of the user who posted the report.</p>
		<p>We reserve the right to make a final decision at our discretion without further negotiation.</p>

		</div>


		</div>
		)
}

export default Home