import React, {useState, useEffect} from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '../State.js'
import axios from 'axios'


import {backEndURL} from '../config.js'


import loginImg from '../assets/icons/_i_icon_03848_icon_038480_256.png'
import logoutImg from '../assets/icons/_i_icon_03850_icon_038500_256.png'



const UserEmail = (props) => {
	
	const {login, signIn} = props.functions
	const [currentUser, setCurrentUser] = useRecoilState(userState)
	const [userEmail,setUserEmail]=useState('')

const token = JSON.parse(sessionStorage.getItem("token"));

useEffect(() => {
	const verify_token = async () => {
		if (token === null) return setCurrentUser({isLoggedIn:false});
		try {
			axios.defaults.headers.common["Authorization"] = token;
			const response = await axios.post(`${backEndURL}/users/verify`)
			// let  {_id, Name, Email} = response.data.succ
			return response.data.ok ? 
			login(token, response.data.user._id, response.data.user.Name, response.data.user.Email, response.data.user.OpenForContact,response.data.user.badges) : logout();
		} catch (error) {

		}
	};
	verify_token();
}, []);

const logout = () => {
	sessionStorage.removeItem("token");
	setCurrentUser({isLoggedIn:false});
};

const enterEmail = (e) => {
	setUserEmail(e.target.value)
}

const emailSubmit=(e)=>{
	e.preventDefault()
	signIn(userEmail)
	setUserEmail('')
}

return (
	<>
	{!currentUser.isLoggedIn ?
		<form onSubmit={emailSubmit}>
		<input id='sign_in_email_input' className='uk-input' onChange={enterEmail} placeholder='Sign in with email' type='email' value={userEmail}/>
		<span className='iconWrapper' style={{"padding":"0.5em"}}><img onClick={emailSubmit} src={loginImg} alt="Search properties reports" title="Search reports" style={{"height":"20px"}}/></span>
		{/*<i onClick={emailSubmit} className="fa-solid fa-arrow-right-to-bracket"></i>*/}
		</form> : 
		<span className='iconWrapper' style={{"padding":"0.5em"}}><img onClick={logout} title='Logout' src={logoutImg} style={{"height":"20px","verticalAlign": "text-bottom"}}/></span>}
		</>
		)
}

export default UserEmail