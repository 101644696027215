import {Suspense} from 'react'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import {Helmet} from "react-helmet";
// global state
import { useSetRecoilState,useRecoilState } from 'recoil'
import { userState } from './State.js'
// components
import Enter from './components/Enter.js'
import AllProps from './components/AllProps.js'
import SingleProp from './components/SingleProp'
import NavBar from './components/Navbar'
import Footer from './components/Footer'
import Spinner from './components/Spinner'
// views
import Home from './views/Home.js'
import MyAccount from './views/MyAccount.js'
import WriteReport from './views/WriteReport.js'
import FAQ from './views/FAQ.js'
import About from './views/About.js'
// configs
import {backEndURL} from './config'
// assets
import {unsplashPictures} from './assets/unsplash.js'
// results[0].urls.full
// helpers
import ScrollToTop from "./helpers/ScrollToTop";
import './App.css';

function App() {

  // const [loggedIn, setLoggedIn] = useRecoilState(userState)
  const setLoggedIn = useSetRecoilState(userState) 
  const [currentUser] = useRecoilState(userState) 

  const login = (token,id,name,email,OpenForContact,badges) => {
    sessionStorage.setItem("token", JSON.stringify(token));
    setLoggedIn({isLoggedIn:true,id,name,email,OpenForContact,badges});
  };

  const signIn = async (email,magicLink) => {
    try{
      let res = await axios.post(`${backEndURL}/users/enter`,{email,magicLink})
      // console.log(res)
      if(res.data.token){
        alert(res.data.message) 
        login(res.data.token,res.data.userID,res.data.userName, res.data.email, res.data.OpenForContacts, res.data.badges)
      }else{ 
        alert(res.data.message)
      }
    }catch(e){
      alert(e)
    }
  }

  return (
    <div className='App uk-container-expand' style={styles.body}>
    <Helmet>
    <title>Good Bad Place – honest reviews from people who lived there</title>
    <meta name="description" content='Real estate review platform, reviews of apartments, houses, buildings, areas' />
    <meta name='keywords' content='real estate reviews, apartments reviews, report landlord, report property' />
    <meta name="og:title" content="Good Bad Place – honest reviews from people who lived there"/>
    <meta name="og:description" content="Real estate review platform, reviews of apartments, houses, buildings, areas"/>
    <meta name="og:image" content="https://goodbadplace.com/images/goodbadplace_com_preview_card.jpg"/>
    <meta name="og:url" content="https://goodbadplace.com"/>
    <meta name="twitter:card" content="summary_large_image"/>
    </Helmet>
    <Router>
    <ScrollToTop/>
    <NavBar functions={{login, signIn}}/>
    <main className='uk-container uk-container-large' id='stretchVertical90vh'>
    <Suspense fallback={<Spinner/>}>
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/properties/*" element={<AllProps/>} />
    <Route path='/properties/:id' element={<SingleProp/>} />
    <Route path="enter/:email/:link" element={<Enter signIn={signIn} />} />
    <Route path="myaccount" element={currentUser.isLoggedIn ? <MyAccount/>:<Navigate to='/'/>} />
    <Route path="writereport" element={<WriteReport functions={{login, signIn}} />} />
    <Route path="/faq" element={<FAQ/>} />
    <Route path="/about" element={<About/>} />
    </Routes>
    </Suspense>
    </main>
    <Footer/>
    </Router>
    </div>
    );
}

const styles = {
  body:{
    minHeight: "100vh",
    backgroundImage: `url(${unsplashPictures.results[Math.floor(Math.random() * unsplashPictures.results.length)].urls.full})`,
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundSize: "cover",
    margin: 0,
  }
}

export default App;
