import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring'
import axios from 'axios'
import { backEndURL } from "../config.js";

const Fade = React.forwardRef(function Fade(props, ref) {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter();
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited();
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
		{children}
		</animated.div>
		);
});

Fade.propTypes = {
	children: PropTypes.element,
	in: PropTypes.bool.isRequired,
	onEnter: PropTypes.func,
	onExited: PropTypes.func,
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
  // border: '1px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function SpringModal({authorID,propID}) {
	const [open, setOpen] = React.useState(false);
	const [message, setMessage]=useState({text:'',sender:'',senderEmail:'',authorID,propID})
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleMessage = (e) => {
		setMessage({...message,[e.target.name]:e.target.value})
	}
	const sendMessage = async()=>{
		const res = await axios
		.post(`${backEndURL}/emails/send_email`, message)
		.catch(function (error) {
			console.log(error);
		});
		alert('Your message has been sent, the author of original post will get in touch with you if they would want to...');

		console.log(message)
		handleClose()
		setMessage({...message, text:'',sender:'',senderEmail:''})
	}
	return (
		<div>
		<button onClick={handleOpen} className='uk-button uk-button-default'>Get in touch with the author</button>
		<Modal
		aria-labelledby="spring-modal-title"
		aria-describedby="spring-modal-description"
		open={open}
		onClose={handleClose}
		closeAfterTransition
		BackdropComponent={Backdrop}
		BackdropProps={{
			timeout: 500,
		}}
		>
		<Fade in={open}>
		<Box sx={style}>
		<Typography id="spring-modal-title" variant="h6" component="h2">
		You can send a message to the author of this report
		</Typography>
		<Typography id="spring-modal-description" sx={{ mt: 2 }}>
		They will see your email and might respond directly to you
		</Typography>
		<div className='flexContainerColumn' onChange={handleMessage}>
		<input className="uk-input" type='text' required={true} placeholder='Your name' name='sender' defaultValue={message.sender}/>
		<input className="uk-input" type='email' required={true} placeholder='Your email' name='senderEmail' defaultValue={message.senderEmail}/>
		<textarea className="uk-textarea" required={true} placeholder='Your message' name='text' defaultValue={message.text}/>
		</div>
		<div className='flexContainerRow' style={{justifyContent:'space-between'}}>
		<button onClick={handleClose} className='uk-button uk-button-default'>Cancel</button>
		<button onClick={sendMessage} className='uk-button uk-button-default'>Send</button>
		</div>
		</Box>
		</Fade>
		</Modal>
		</div>
		);
}