// export let backEndURL = 'http://localhost:4444'
// export let clientUrl = 'http://localhost:1337'


// export let backEndURL = 'https://sleepy-beyond-29941.herokuapp.com'
// export let clientUrl = 'https://sleepy-beyond-29941.herokuapp.com'



export let backEndURL = window.location.hostname === `localhost` 
? 'http://localhost:4444'
: 'https://goodbadplace.com'


export let clientUrl = window.location.hostname === `localhost` 
? 'http://localhost:1337'
: 'https://goodbadplace.com'
