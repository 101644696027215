import React, {useState, useEffect} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { requestAtom } from '../State.js'
import { useRecoilState } from 'recoil'
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring'
import axios from 'axios'
import { backEndURL } from "../config.js";
import deleteImg from '../assets/icons/_i_icon_06564_icon_065640_256.png'

const Fade = React.forwardRef(function Fade(props, ref) {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter();
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited();
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
		{children}
		</animated.div>
		);
});

Fade.propTypes = {
	children: PropTypes.element,
	in: PropTypes.bool.isRequired,
	onEnter: PropTypes.func,
	onExited: PropTypes.func,
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
  // border: '1px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function SpringModal({propID}) {
	let navigate = useNavigate();
	const [reqAtom,setReqAtom]=useRecoilState(requestAtom)
	const [open, setOpen] = React.useState(false);
	// const [message, setMessage]=useState({text:'',sender:'',senderEmail:'',authorEmail,AuthorName,propID})
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	// const handleMessage = (e) => {
	// 	setMessage({...message,[e.target.name]:e.target.value})
	// }
	const sendMessage = async()=>{
		const res = await axios
		.post(`${backEndURL}/props/delete`, {_id:propID})
		.catch(function (error) {
			console.log(error);
		});
		alert('Your report has been removed...');
		setReqAtom(true)
		// console.log(message)
		handleClose()
		navigate('/myaccount')
		// setMessage({...message, text:'',sender:'',senderEmail:''})

	}
	return (
		<div>
		<img src={deleteImg} onClick={handleOpen} title='Delete report' style={{"height":"20px"}}/>
		<Modal
		aria-labelledby="spring-modal-title"
		aria-describedby="spring-modal-description"
		open={open}
		onClose={handleClose}
		closeAfterTransition
		BackdropComponent={Backdrop}
		BackdropProps={{
			timeout: 500,
		}}
		>
		<Fade in={open}>
		<Box sx={style}>
		<div className='flexContainerColumn'>
		<Typography id="spring-modal-title" variant="h4" component="h2">
		Delete report?
		</Typography>
		<Typography id="spring-modal-description" sx={{ mt: 2 }}>
		This action is irreversible!
		</Typography>
		</div>
		<div className='flexContainerRow' style={{justifyContent:'space-between'}}>
		<button onClick={handleClose} className='uk-button uk-button-default'>Cancel</button>
		<button onClick={sendMessage} className='uk-button uk-button-default uk-button-danger'>Delete</button>
		</div>
		</Box>
		</Fade>
		</Modal>
		</div>
		);
}