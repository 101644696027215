import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from 'recoil'
import { propState,userState } from '../State.js'
import { clientUrl } from '../config'
import axios from 'axios'
import {backEndURL} from '../config.js'
import ContactAuthor from './ContactAuthorModal'
import DeleteReport from './DeleteReportModal'
import Stars from '../components/Stars.js'

import {Helmet} from "react-helmet";

import likeImg from '../assets/icons/_i_icon_01780_icon_017800_256.png'

const SingleProp = () => {
	const navigate=useNavigate()
	const propID = useParams()

	const [propData, setPropData] = useRecoilState(propState)
	const [p, setP] = useState( propData.find((p)=>p._id===propID.id) )
	!p && setTimeout(()=>navigate("/"),5000)
	const propIdx = propData.findIndex((p)=>p._id===propID.id)
	const [currentUser, setCurrentUser] = useRecoilState(userState)

	const thank = async () => {
		let thanked = JSON.parse(sessionStorage.getItem('thanked')) || []
		if (!thanked.includes(p._id)){
			(async function () {
				await axios.post(`${backEndURL}/props/thank`,{_id:p._id,thanks:p.thanks+1})
			})()
			thanked.push(p._id)
			sessionStorage.setItem('thanked', JSON.stringify(thanked))
			setP({...p,thanks:p.thanks+1})
		}

	}



	return <div className="uk-container uk-container-small">
	<Helmet>
	<title>
		{`${p.Street} ${p.StreetNumber}, ${p.PropFloor&&p.PropFloor}${p.PropDoor&&` - ${p.PropDoor},`} ${p.City}, ${p.Country}`}
	</title>
	<meta name="description" content={`Review of a ${p.PropType.replace('_',' ')} at${p.Street} ${p.StreetNumber}, ${p.PropFloor&&p.PropFloor}${p.PropDoor&&` - ${p.PropDoor},`} ${p.City}, ${p.Country}`} />
	<meta name='keywords' content={`Reviews of a ${p.PropType.replace('_',' ')} at${p.Street} ${p.StreetNumber}, ${p.PropFloor&&p.PropFloor}${p.PropDoor&&` - ${p.PropDoor},`} ${p.City}, ${p.Country}`} />
	</Helmet>
	{ !!p ?
	<div className='uk-card uk-card-default uk-card-body uk-width-1@m'>
	<h1>A {p.PropType.replace('_',' ')} at {p.Street} {p.StreetNumber}, {p.PropFloor&&p.PropFloor}{p.PropDoor&&` - ${p.PropDoor},`} {p.City}, {p.Country}</h1>

	{/* social sharing */}
	<div className='socialsharebuttons'>
	<div className='socialshare'>
	<a className="twitter-share-button"
	target='_blank' rel='noreferrer'
	href={`https://twitter.com/intent/tweet?text=Check out a review of a ${p.PropType.replace('_',' ')} at ${p.Street} ${p.StreetNumber}, ${p.PropFloor}${p.PropDoor&&' - '}${p.PropDoor}, ${p.City}, ${p.Country} from Prop Report&url=${clientUrl}/properties/${propID.id}`}
	data-size="large">
	<i className=" highlighted fa-brands fa-twitter-square"></i>
	</a>
	</div>

	<div className="fb-share-button socialshare" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
	<a target="_blank" rel='noreferrer' href={`https://www.facebook.com/sharer/sharer.php?u=${clientUrl}/properties/${propID.id}`} className="fb-xfbml-parse-ignore">
	<i className=" highlighted fa-brands fa-facebook-square"></i>
	</a>
	</div>
	</div>
{/* end social sharing */}


	<Stars stars={p.PropsRating}/>
	<p>{p.PropReview}</p>

	<section className='flexibleGrid'>

	{p.PropAmenities.length>0&&<div>
	<h2>Amenities</h2>
	<ul>
	{p.PropAmenities.map((a,i)=><li key={i}>{a.name}</li>)}
	</ul>
	</div>
}

{(p.FlatmatesReview || p.FlatmatesRating) &&
<div>
<h2>Flatmates</h2>
{p.FlatmatesRating&&<p>{<Stars stars={p.FlatmatesRating}/>}</p>}
<p>{p.FlatmatesReview}</p>
</div>
}

{(p.NeighboursReview || p.NeighboursRating) &&
<div>
<h2>Neighbours</h2>
{p.NeighboursRating&&<p>{<Stars stars={p.NeighboursRating}/>}</p>}
<p>{p.NeighboursReview}</p>
</div>
}

{(p.OwnerReview || p.OwnerRating) &&
<div>
<h2>Owners</h2>
{p.OwnerRating&&<p>{<Stars stars={p.OwnerRating}/> }</p>}
<p>{p.OwnerReview}</p>
</div>
}

{(p.BuildingReview || p.BuildingRating) &&
<div>
<h2>Building</h2>
{p.BuildingRating&&<p>{<Stars stars={p.BuildingRating}/> }</p>}
<p>{p.BuildingReview}</p>
</div>
}

{(p.AreaReview || p.AreaRating) &&
<div>
<h2>Area</h2>
{p.AreaRating&&<p>{<Stars stars={p.AreaRating}/> }</p>}
<p>{p.AreaReview}</p>
</div>
}

</section>
{/*</Grid>*/}

<p>Reported {/*by {p.User?.Name} */}on {new Date(p.created).toLocaleDateString()} {/*who lived there in {p.YearFrom} - {p.YearTo}*/}</p>
<p>Report's ID: {p._id}</p>

<div className='flexContainerRow' style={{justifyContent:'space-between'}}>
<span className='thanks'>
<span title='Thank the author' onClick={thank}><img src={likeImg} style={{"height":"20px","verticalAlign": "text-bottom"}}/></span> {p.thanks}</span> 
{ (p.User&&p.User?.OpenForContact&&currentUser?.id !== p.User?._id)&&<ContactAuthor authorID={p.User?._id} propID={p._id}/>}
{(p.User&&currentUser?.id === p.User?._id)&&<DeleteReport propID={p._id}/>}
</div>

</div>
:
<div className='uk-card uk-card-default uk-card-body uk-width-1@m'>
<h1>This report is not found or has been removed...</h1>
<p>We will redirect you to the home page instead...</p>
</div>
}
</div>
}	


export default SingleProp