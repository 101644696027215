import React from 'react'
// spinners import stuff
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;
// end spinners import stuff

const Spinner = () => {
	return (
		<div className='spinnerContainer'>
		<FadeLoader color={'black'} loading={true} css={override} size={50} />
		</div>
		)
}

export default Spinner