import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';

import { useRecoilValue, useRecoilState } from 'recoil'

// import { propState, userState } from '../State.js'
import { userState,requestAtom } from '../State.js'

import FeedbackRating from '../components/FeedbackRating.js'
import UserEmail from '../components/UserEmail.js'

import star from '../assets/icons/star.png'


import { backEndURL } from "../config.js";
import axios from 'axios'


const WriteReport = (props) => {

	const currentUser = useRecoilValue(userState)
	// const propData = useRecoilValue(propState)
	console.log('currentUser: ',currentUser)
	const [reqAtom,setReqAtom]=useRecoilState(requestAtom)

	let navigate = useNavigate()
	// const stars=[star1,star2,star3]
	
	// years dropdown
	let years = (Array.from(Array(51), (x, index) => ((new Date()).getFullYear()-50) + index * 1)).reverse()

	// enter details data
	const [streetAddress, setStreetAddress]=useState(null)
	const [formData, setFormData]=useState(
	{
		User: currentUser.id,
		PropAmenities:[
			],
		PropDoor: null,
		PropFloor: null,
		PropType: null,
		YearFrom: null,
		YearTo: null,
		areaRating: null,
		areaReview: null,
		buildingRating: null,
		buildingReview: null,
		flatmatesRating: null,
		flatmatesReview: null,
		neighboursRating: null,
		neighboursReview: null,
		ownerRating: null,
		ownerReview: null,
		propertyRating: null,
		propertyReview: null,
	})


	console.log(streetAddress,formData.PropType)
	useEffect(()=>{
		streetAddress && getPostCode(streetAddress.value.description,streetAddress.value.place_id)
	},[streetAddress])

	const handleForm = (e)=>{
		let tempFormData = {...formData}
		e.target.type === 'radio' && setFormData({...tempFormData, PropType:e.target.value })
		if( e.target.name.includes('PropAmenities.name') ) {
			let amName = e.target.name.replace('PropAmenities.name:','')
			let amenityIndex = tempFormData.PropAmenities.findIndex(am=>am.name===amName)
			if(amenityIndex !== -1){
				tempFormData.PropAmenities[amenityIndex]={
					name:e.target.name.replace('PropAmenities.name:',''),
					state:e.target.checked
				}

			}else{
				tempFormData.PropAmenities.push({
					name:e.target.name.replace('PropAmenities.name:',''),
					state:e.target.checked
				})
			}

			setFormData({...tempFormData}) 
		}else{
			setFormData({...formData, [e.target.name]:e.target.value })
		}
	}

	const starRating = (e,subj)=>{
		setFormData({...formData,[`${subj}Rating`]:e.target.name[4]})
		console.log(formData)
	}

	// getting postcode
	const getPostCode = async (address, placeId) => {
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		const [place] = await geocodeByPlaceId(placeId);
		const { long_name: PostalCode = '' } =
		place.address_components.find(c => c.types.includes('postal_code')) || {};
		setFormData({
			...formData, 
			PostalCode, 
			Coordinates: latLng,
			DetailedAddress:place.address_components})
	}
	// end getting postcode

	const sendForm = async(e)=>{
		e.preventDefault()
		if(streetAddress){
			try {
				let save = await axios.post(`${backEndURL}/props/newreport`,{...formData,streetAddress})
				console.log(save)
				setReqAtom(true)
				save.data.ok?navigate('/myaccount'):alert('Ooops, something went wrong!', save.data.e)
			}catch(e){
			}
		}else{
			alert('Oi, we need to know the street address...')
		}
	}

	// let renderAll =(data)=> {
	// 	return data.map((p,idx)=>(
	// 		<div key={idx} className='oneProp uk-card uk-card-default uk-card-body uk-width-1@m'>
	// 		<p className="uk-card-title">{p.Street} {p.StreetNumber}, {p.PropNumber}</p>
	// 		<p>{p.Country} {p.City}</p>

	// 		<img src={stars[p.PropsRating]} />
	// 		<p>PropReview: {p.PropReview}</p>

	// 		<button className='uk-button uk-button-default' onClick={()=>navigate(`/properties/${p._id}`)}>Read full report</button>

	// 		</div>
	// 		))
	// }

	return (
		<>
		{!currentUser.id ? <div className="seeThrough uk-card uk-card-default uk-card-body uk-card-media-right uk-width-1-2@m">
		<h3 className="uk-card-title">Please sign in before writing a review</h3>
		<div className='oneProp uk-card uk-card-default uk-card-body'>
		<p>Writing reviews is what this platform is about. Although they are anonymous, we will need your email to assign the ownership of the reviw. Other suers will not see it and it will never be disclosed. </p>
		<p>Your email will allow you to sign in later and access your dashboard to see your reviews and manage them.</p>
		<p>Please enter your email in the input below and press Sign In button. You will get a confirmation email with a link, once clicked you will be able to start reviewing the properties.</p>
		<UserEmail functions={props.functions}/>
		</div>
		</div> :

		<div className="seeThrough uk-card uk-card-default uk-card-body uk-card-media-right uk-width-1-2@m">
		<h3 className="uk-card-title">Write a review</h3>
		
		<div className='oneProp uk-card uk-card-default uk-card-body '>
		<form className='newReport' onChange={handleForm} onSubmit={sendForm}>
		<p>The street address with building number *</p>
		<GooglePlacesAutocomplete
		selectProps={{
			streetAddress,
			onChange: setStreetAddress,
		}}
		/>

		<hr/>

		<p className='flexContainerRow uk-card-hover'>
		Floor: <input  name='PropFloor' className='shortInput uk-input' />
		Door: <input  name='PropDoor' className='shortInput uk-input' />
		</p>

		<hr/>

		<p>You lived there (would be hidden)</p>
		<div className='flexContainerRow'>
		<p>from </p>
		<label htmlFor="yearFrom" />
		<select
		required={true}
		name='YearFrom'
		className='uk-select'
		id="year"
		defaultValue={null}
		>
		<option>Select a year...</option>
		{years.map((item, idx) => {
			return (
				<option key={idx} value={item}>
				{item}
				</option>
				);
		})}
		</select>
		<p>to</p>
		<label htmlFor="yearTo" />
		<select
		required={true}
		name='YearTo'
		className='uk-select'
		id="year"
		defaultValue={null}
		>
		<option>Select a year...</option>
		{years.map((item, idx) => {
			return (
				<option key={idx} value={item}>
				{item}
				</option>
				);
		})}
		</select>
		</div>	

		<hr/>

		<div className='flexContainerRow'>	
		<p>Whole place or a room? *</p>
		<input required={true} className='uk-radio' type="radio" value="whole_place" checked={formData.PropType!=='whole_place' ? false:true} name="PropType" /> Entire place
		<input required={true} className='uk-radio' type="radio" value="room" checked={formData.PropType!=='room'? false:true} name="PropType" /> Room
		</div>

		<hr/>

		<p>Did it have some of this good stuff?</p>
		<div className='flexContainerRowWithWrap'>
		<span>
		<input className='uk-checkbox' type="checkbox" id="ac" name="PropAmenities.name:A/C" defaultChecked={false}/>
		<label htmlFor="ac">Air&nbsp;conditioning</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="heating" name="PropAmenities.name:Heating" defaultChecked={false}/>
		<label htmlFor="heating">Heating</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="balcony" name="PropAmenities.name:Balcony/terrace" defaultChecked={false}/>
		<label htmlFor="balcony">Balcony/terrace</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="elevator" name="PropAmenities.name:Elevator" defaultChecked={false}/>
		<label htmlFor="elevator">Elevator</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="Doorman" name="PropAmenities.name:Doorman" defaultChecked={false}/>
		<label htmlFor="Doorman">Doorman</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="Parking" name="PropAmenities.name:Parking" defaultChecked={false}/>
		<label htmlFor="Parking">Parking</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="Pool" name="PropAmenities.name:Pool" defaultChecked={false}/>
		<label htmlFor="Pool">Pool</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="washer" name="PropAmenities.name:Washer" defaultChecked={false}/>
		<label htmlFor="washer">Washer</label>
		</span>
		<span>
		<input className='uk-checkbox' type="checkbox" id="dryer" name="PropAmenities.name:Dryer" defaultChecked={false}/>
		<label htmlFor="dryer">Dryer</label>
		</span>

		</div>

		<hr/>

		<FeedbackRating data={{star,subj:'property',starRating, handleForm}}/>

		<hr/>

		<FeedbackRating data={{star,subj:'owner',starRating, handleForm}}/>

		<hr/>

		<FeedbackRating data={{star,subj:'building',starRating, handleForm}}/>

		<hr/>

		<FeedbackRating data={{star,subj:'area',starRating, handleForm}}/>

		<hr/>

		<FeedbackRating data={{star,subj:'flatmates',starRating, handleForm}}/>

		<hr/>

		<FeedbackRating data={{star,subj:'neighbours',starRating, handleForm}}/>


		<hr/>

		<button className="uk-button uk-button-default">Submit</button>

		</form>
		</div>
		</div>
		 }
		</>
		)
}

export default WriteReport