import React from 'react'
import {NavLink} from 'react-router-dom'
import Grid from 'react-easy-css-grid-layout'
import './Footer.css'

const Footer = () => {
	return (
		<footer style={styles.main}>
		{/*<Grid columns={"2fr 1fr 1fr 1fr"} gap={'1em'}>*/}
		<Grid columns={"3"} gap={'5em'}>
		<div>
		<h4 style={styles.headings}>GoodBadPlace.com</h4>
		<div style={styles.p}>
		<NavLink   
		to={'/'}
				// style={ ({isActive}) => (
				// 	isActive ? styles.activeLink : styles.defaultLink
				// 	)}
		>Homepage</NavLink>
		</div>
		<div style={styles.p}>
		<NavLink   
		to={'/properties'}
				// style={ ({isActive}) => (
				// 	isActive ? styles.activeLink : styles.defaultLink
				// 	)}
		>Search reports</NavLink></div>

		</div>
		<div>
		<h4 style={styles.headings}>Project</h4>
		<div style={styles.p}>
		<NavLink  
		to={'/about'}
		// style={ ({isActive}) => (
		// 	isActive ? styles.activeLink : styles.defaultLink
		// 	)}
		>About</NavLink>
		</div>
		<div style={styles.p}>
		<p>Made at <a className='inlineLink' href='https://barcelonacodeschool.com'>BCS</a></p>
		</div>
		</div>
		<div>
		<h4 style={styles.headings}>Support</h4>
		<div style={styles.p}>
		<a href="mailto:team1@goodbadplace.com">Contact&nbsp;Us</a></div>
		<div style={styles.p}>
		<NavLink  
		to={'/faq'}
		// style={ ({isActive}) => (
		// 	isActive ? styles.activeLink : styles.defaultLink
		// 	)}
		>FAQs</NavLink>
		</div>
		</div>
{/*		<div>
		<h4 style={styles.headings}>Community</h4>
		<p style={styles.p}>Guidlines</p>
		<p style={styles.p}>Legal Support</p>
		</div>
		<div>
		<h4 style={styles.headings}>For Landlords</h4>
		<p style={styles.p}>List your property</p>
		<p style={styles.p}>Policy</p>
		</div>*/}
		</Grid>

		</footer>
		)
}

let styles = {
	main:{
		color:'white',
		backgroundColor:'rgb(156 156 156)',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		opacity: '.9',
		padding: '1em 1em 3em',
	},
	p:{
		padding:'.5em 0',
		margin:'.5em 0',
		margin: '0',
	},
	a:{
		color:'white'
	},
	headings:{
		color:'white'
	},
	activeLink: {
		color:'#0393A3'
	},
	defaultLink: {
		color:'#29DCF0'
	}
}



export default Footer
