import { atom, selector } from 'recoil'
import axios from "axios";
import { backEndURL } from "./config.js";

// export const userState = atom({
// 	key: 'loggedIn',
// 	default: false,
// });

export const userState = atom({
	key: 'currentUser',
	default:{isLoggedIn:false,id:null,name:null,email:null,OpenForContact:null,badges:null}
});

export const requestAtom = atom({
  key: "request",
  default: {
     request: null
  },
});

export const propState = selector({
	key: "properties",
	get: async ({ get }) => {
		// get(userState)
		// get(requestAtom)
		const properties = await axios.get(`${backEndURL}/props/getall`);
		return properties.data
	},
});