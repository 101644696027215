import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import { useRecoilValue } from 'recoil'
import { propState } from '../State.js'

import Stars from './Stars'

const AllProps = () => {

	let navigate = useNavigate()
	const propData = useRecoilValue(propState)
	// const stars=[star1,star2,star3]
	const [propsToRender, setPropsToRender]=useState(propData)

	let renderAll =(data)=> {
		return data.map((p,idx)=>(
			<div key={idx} className='seeThrough oneProp uk-card uk-card-default uk-card-body uk-width-1@m"'>
			<div className='uk-card uk-card-default uk-card-body uk-card-hover'>
			<p className="uk-card-title">{p.Street} {p.StreetNumber}, {p.PropFloor&&`${p.PropFloor}`} {p.PropDoor&&` – ${p.PropDoor},`} {p.PostalCode}</p>
			<p>{p.City}, {p.Country}</p>

			<Stars stars={p.PropsRating}/>
			<p className='propReview'>{p.PropReview}</p>

			<button className='uk-button uk-button-default' onClick={()=>navigate(`/properties/${p._id}`)}>Read full report</button>
			</div>
			</div>
			))
	}

	let filterProps=(e)=>{
		setPropsToRender(propData.filter((prop)=>
			Object.values(prop).flat(Infinity).join(' ').toLowerCase().includes(e.target.value.toLowerCase()) 
			))
	}

	return (
		<div >
		<section className='seeThrough propsSearch uk-margin uk-card uk-card-default uk-card-body uk-card-hover uk-width-1@m'>
		{/*<form onSubmit={(e)=>e.preventDefault()}>*/}
		<input className="uk-input" type="text" placeholder="Search by street name, postcode, city or anything..." onChange={filterProps}/>
		{/*</form>*/}
		</section>
		<section className='allPropsContainer'>
		{renderAll(propsToRender)}
		</section>
		</div>
		)
}

export default AllProps